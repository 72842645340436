import { isAnonymous } from "@reco-m/core";
import { CommentAuditStatusEnum, RateAuditStatusEnum } from "./common";

/**
 * 点击发表，进行校验
 * @param self this
 * @param commentContent 回复内容
 * @param [otherItem] 其他信息
 * @returns
 */
export function beforeSubmitComment(self, commentContent: any, otherItem?) {
    if (self.checkIsPreview && self.checkIsPreview()) {
        return;
    }

    if (isAnonymous()) {
        self.dispatch({ type: "input", data: { isShowModal: true } });
    } else {
        self.dispatch({ type: "input", data: { submitLoading: true, showReplyModal: "" } });
        formatCommentContent(self, commentContent, otherItem);
    }
}

/**
 * 格式化内容，将回车转为<br />
 * @param self this
 * @param commentContent 回复内容
 * @param [otherItem] 其他信息
 */
export function formatCommentContent(self, commentContent: any, otherItem?) {
    commentContent = commentContent.replace(/[\n]{1,}/g, "<br/>");

    while (commentContent.indexOf("<br/>") === 0) {
        commentContent = commentContent.replace("<br/>", "");
    }

    if (!checkCommentContent(self, commentContent) && self.submitContent) {
        otherItem ? self.submitContent(commentContent, otherItem) : self.submitContent(commentContent);
    }
}

/**
 * 检查输入的内容
 */
export function checkCommentContent(self, commentContent) {
    let flag = false;
    if (!commentContent || !commentContent?.trim() || commentContent === "<br/>") {
        self.message.error("评价内容不能为空", 2);
        self.dispatch({ type: "input", data: { submitLoading: false, replySubmitLoading: false } });
        flag = true;
    }

    return flag;
}

/**
 * 获取评论审核状态
 * @param status
 * @param type
 * @returns
 */
export function getCommentAuditStatus(status, type: "label" | "class" = "label") {
    switch (status) {
        case CommentAuditStatusEnum.waitAudit:
            return type === "class" ? "orange" : "待审核";
        case CommentAuditStatusEnum.fail:
            return type === "class" ? "error" : "审核退回";
        case CommentAuditStatusEnum.pass:
            return type === "class" ? "success" : "审核通过";
        default:
            return "--";
    }
}

/**
 * 获取评价审核状态
 * @param status
 * @param type
 * @returns
 */
export function getRateAuditStatus(status, type: "label" | "class" = "label") {
    switch (status) {
        case RateAuditStatusEnum.waitAudit:
            return type === "class" ? "orange" : "待审核";
        case RateAuditStatusEnum.fail:
            return type === "class" ? "error" : "审核退回";
        case RateAuditStatusEnum.pass:
            return type === "class" ? "success" : "审核通过";
        default:
            return "--";
    }
}

import moment, { Moment, unitOfTime } from "moment";

import { transformAssetsUrl, getObjectProp, formatDate } from "@reco-m/core";
import { changeUrlParams } from "@reco-w/core-ui";
import { CommonSearchParamInUrlEnum } from "./ipark.common.enum";
/**
 * 获取封面图片
 * @param item
 * @param [isAvatar] 是否是头像，获取不同的默认图片，默认值是false
 * @returns
 */
export function getCoverPicture(item, isAvatar = false) {
    if (!item) {
        return "";
    }

    if (isAvatar) {
        if (item.avatarPicUrl || item.avatarPath || item.avatarPictureUrl || item.profilePath) {
            return transformAssetsUrl(item.avatarPicUrl || item.avatarPath || item.avatarPictureUrl || item.profilePath);
        } else {
            return "assets/images/myBackground.jpg";
        }
    } else if (item.coverPictureUrl || item.coverUrl) {
        return transformAssetsUrl(item.coverPictureUrl || item.coverUrl);
    } else if ((item.pictureUrlList || item.pictureList)?.length > 0) {
        return transformAssetsUrl((item.pictureUrlList || item.pictureList)[0]);
    } else {
        return "assets/images/nopic.jpg";
    }
}
/**
 * 获取上/下条相关信息
 * @param list 列表
 * @param curId 当前信息Id，用于在list中获取当前位置，从而获取上下条信息
 * @param [titleMap] 绑定的标题字段名，默认为title
 * @param [titleOfNoPre] 没有上一条信息时的标题，默认为 没有上一篇
 * @param [titleOfNoNext] 没有下一条信息时的标题，默认为 没有下一篇
 * @returns
 */
export function getPreviousOrNextInfo(list: any[], curId: any, titleMap = "title", titleOfNoPre = "没有上一篇", titleOfNoNext = "没有下一篇") {
    let index: number; // 当前信息所在位置
    let preTitle: string = ""; // 上一条标题
    let nextTitle: string = ""; // 下一条标题
    let preID: number = 0; // 上一条id
    let nextID: number = 0; // 下一条id

    if (list?.length > 0 && curId) {
        index = list.findIndex((item) => item!.id === curId);
        preTitle = index - 1 >= 0 ? list[index - 1][titleMap] : titleOfNoPre;
        nextTitle = index === list.length - 1 ? titleOfNoNext : list[index + 1][titleMap];
        preID = index - 1 >= 0 ? list[index - 1] && list[index - 1].id : 0;
        nextID = index === list.length - 1 ? 0 : list[index + 1] && list[index + 1].id;
    }

    return { preTitle, nextTitle, preID, nextID };
}

/**
 * 获取上/下条相关信息
 * @param obj 对象
 * @param curId 当前信息Id，用于在list中获取当前位置，从而获取上下条信息
 * @param [titleMap] 绑定的标题字段名，默认为title
 * @param [titleOfNoPre] 没有上一条信息时的标题，默认为 没有上一篇
 * @param [titleOfNoNext] 没有下一条信息时的标题，默认为 没有下一篇
 * @returns
 */
export function getPreviousOrNextInfoByObj(
    obj: any,
    previousMap = "previousData",
    nextMap = "nextData",
    titleMap = "title",
    titleOfNoPre = "没有上一篇",
    titleOfNoNext = "没有下一篇"
) {
    return {
        preTitle: (obj && obj[previousMap] && obj[previousMap][titleMap]) || titleOfNoPre,
        nextTitle: (obj && obj[nextMap] && obj[nextMap][titleMap]) || titleOfNoNext,
        preID: obj && obj[previousMap]?.id,
        nextID: obj && obj[nextMap]?.id,
    };
}

/**
 * 页面跳转
 */
export function changePage(currentPage, pageSize, self) {
    self.goTo(changeUrlParams(window.location.search, { [CommonSearchParamInUrlEnum.pageIndex]: currentPage, [CommonSearchParamInUrlEnum.pageSize]: pageSize }));

    window.scroll(0, 0);
}

/**
 * 搜索
 * @param self 页面this
 * @param name 标签id
 */
export function searchBox(self, id) {
    let searchHeight = $(`#${id}`);
    if (searchHeight.outerHeight()! > 60) {
        self.dispatch({
            type: "input",
            data: { ["openInfos_" + id]: true },
        });
    }
}

/**
 * 拆分数组
 * @param array
 * @param subGroupLength
 * @returns
 */
export function group(array: any, subGroupLength) {
    let index = 0;
    let newArray: any[] = [];
    while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
    }
    return newArray;
}

/**
 * 设置高德地图的key
 * @param key
 */
export function setMapScript(key) {
    const id = getObjectProp(client, "amap.script.id", `amap1`);
    const version = getObjectProp(client, "amap.script.version", `1.4.15`);
    const selfKey = getObjectProp(client, "amap.script.key", key);
    const element = document.getElementById(id);
    if (element && element?.getAttribute("src")?.includes(key)) {
        return;
    } else if (element) {
        element.remove();
    }
    const src = getObjectProp(client, "amap.script.src", `https://webapi.amap.com/maps?v=${version}&`) + `key=${selfKey}`;
    let script = document.createElement("script"); //创建一个script标签
    script.type = "text/javascript";
    script.id = id;
    script.src = src;

    document.getElementsByTagName("head")[0].appendChild(script);
}

/**
 * 获取年月（YYYYMM）
 * @param time
 * @param [isNumber]
 * @returns
 */
export function getYearMonth(time: Date | string, isNumber?) {
    const yearMonth = formatDate(time, "yyyyMM");
    return isNumber ? Number(yearMonth) : yearMonth;
}

/**
 * 将年月格式化为日期
 * @param yearMonth
 * @param [fmt]
 * @param [isReturnMoment] 是否返回moment格式
 * @returns
 */
export function formatDateByYearMonth(yearMonth: string | number, fmt: string = "yyyy-MM-dd", isReturnMoment?) {
    if (!yearMonth) {
        return "--";
    }
    yearMonth = String(yearMonth);
    const year = Number(yearMonth.substring(0, 4));
    const month = Number(yearMonth.substring(4)) - 1;
    return isReturnMoment ? moment().year(year).month(month) : moment().year(year).month(month).format(fmt);
}

/**
 * 获取两个时间的差值
 * @param [startDate] 开始时间，如果不传则默认为当前时间
 * @param [endDate] 结束时间，如果不传则默认为当前时间
 * @param [type] 类型
 * @returns 返回为开始时间-结束时间
 */
export function getDateDiff(startDate: string | Date | Moment = moment(), endDate: string | Date | Moment = moment(), type: unitOfTime.Diff = "days") {
    return moment(startDate).diff(endDate, type);
}

/**
 * 获取两个时间的完整差值，以xx年xx月xx天xx小时xx分xx秒返回
 * @param [startDate]
 * @param [endDate]
 * @returns
 */
export function getWholeDiffTime(startDate: string | Date | Moment = moment(), endDate: string | Date | Moment = moment()) {
    const diffMilliseconds = getDateDiff(startDate, endDate, "milliseconds");
    const duration = moment.duration(diffMilliseconds);
    const year = duration.years() ? duration.years() + "年" : "";
    const month = duration.months() ? duration.months() + "个月" : "";

    return year + month + duration.days() + "天" + duration.hours() + "小时" + duration.minutes() + "分" + duration.seconds() + "秒";
}

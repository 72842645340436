import { freeze } from "immer";
import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { CoreEffects, CoreReducers } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { IParkBindTableNameEnum } from "@reco-w/common-common";
import { cashAliPayService, myOrderService, payService, resourceService, wechatPayService } from "@reco-w/order-service";

import { Namespaces, OrderStatusEnum, OrderSubmitSuccessPageShowType, OrderItemSkuTypeEnum, PaymentTypeEnum, PayWayEnum, TradeStatusEnum } from "./common";
import { integralEventService } from "@reco-w/member-service";
import { getOrderType, isRoom } from "./utils";

export namespace orderSubmitModel {
    export const namespace = Namespaces.orderSubmit;

    export const state: any = freeze({}, !0);
    export type StateType = typeof state;

    /**
     * 预订园区资源积分规则代码
     */
    const resourceLoyaltyCode = "ReserveReach";

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, id, showTypeCallback }, { put }) {
            try {
                yield put({ type: "getOrderDetailAction", message, id, showTypeCallback });
            } catch (e) {}
        },

        /**
         * 获取订单详情
         */
        *getOrderDetailAction({ message, id, showTypeCallback }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const result = yield call(myOrderService.get, id);

                yield put({ type: "getOrderShowType", message, detail: result, callback: showTypeCallback });

                if (result?.totalAmount && [OrderStatusEnum.comment, OrderStatusEnum.succeeded, OrderStatusEnum.used].contains(result?.orderStatus)) {
                    yield put({ type: "getOrderPayInfoAction", message, id });
                    yield put({ type: "getIntegral", message });
                }

                yield put({ type: "input", data: { orderdetail: result } });
            } catch (e) {
                yield call(message!.error, "getOrderDetailAction：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取展示类型
         */
        *getOrderShowType({ message, detail, callback }, { call, put }) {
            try {
                let showType = OrderSubmitSuccessPageShowType.noconfirmed;
                if ([OrderStatusEnum.closed, OrderStatusEnum.unRefunded, OrderStatusEnum.refunded, OrderStatusEnum.refundFail].contains(detail.orderStatus)) {
                    // 如果是已取消或退款状态
                    showType = OrderSubmitSuccessPageShowType.cancel;
                } else if (detail?.totalAmount && detail?.orderStatus === OrderStatusEnum.unpaid) {
                    // 如果总金额不为0且未付款状态
                    showType = OrderSubmitSuccessPageShowType.unpaid;
                } else if (
                    ((!detail?.totalAmount &&
                        detail?.orderItem?.some((x) => [OrderItemSkuTypeEnum.coupon, OrderItemSkuTypeEnum.discount, OrderItemSkuTypeEnum.integral].contains(x.skuType))) ||
                        detail?.totalAmount) &&
                    [OrderStatusEnum.comment, OrderStatusEnum.succeeded, OrderStatusEnum.used, OrderStatusEnum.inuse].contains(detail?.orderStatus)
                ) {
                    // (如果总金额为0且存在优惠 或 总金额不为0) 且 已付款状态
                    showType = OrderSubmitSuccessPageShowType.paid;
                } else if ([OrderStatusEnum.unconfirmed, OrderStatusEnum.failed].contains(detail?.orderStatus)) {
                    // 如果是待审核或审核失败状态
                    const resource = yield call(resourceService.get, detail?.bindTableId);
                    const isAudit = resource?.config?.isAudit;
                    if (isAudit) {
                        showType = OrderSubmitSuccessPageShowType.unconfirmed;
                    } else {
                        showType = OrderSubmitSuccessPageShowType.noconfirmed;
                    }
                }
                yield put({ type: "input", data: { showType } });

                if (callback) {
                    yield call(callback, showType, detail);
                }
            } catch (e) {
                yield call(message!.error, "getOrderShowType：" + e.errmsg);
            }
        },

        /**
         * 获取订单支付信息
         */
        *getOrderPayInfoAction({ message, id }, { call, put }) {
            try {
                const result = yield call(payService.getList, { bindTableName: IParkBindTableNameEnum.order, bindTableId: id });
                yield put({ type: "input", data: { payInfo: result?.length && result[0] } });
            } catch (e) {
                yield call(message!.error, "getOrderPayInfoAction：" + e.errmsg);
            }
        },

        /**
         * 订单操作（自动取消）
         */
        *cancelOrder({ orderId, callBack }, { call }) {
            try {
                yield call(myOrderService.cancelOrder, orderId);
                // if (callBack) {
                //     callBack("您的订单已自动取消成功，欢迎再次预订。");
                // }
            } catch (e) {
                // if (callBack) {
                //     callBack(e?.errmsg || "您的订单取消失败。");
                // }
            } finally {
                if (callBack) {
                    callBack("您的订单已自动取消成功，欢迎再次预订。");
                }
            }
        },

        /**
         * 获取积分事件
         * @param { message }
         * @param {  put, call }
         */
        *getIntegral({ message }, { put, call }) {
            try {
                const result = yield call(integralEventService.getList, { code: resourceLoyaltyCode });

                yield put({ type: "input", data: { loyaltyEvent: result?.length && result[0] } });
            } catch (e) {
                yield call(message!.error, "getIntegral：" + e.errmsg);
            }
        },

        /**
         * 获取积分事件
         * @param { message, callback }
         * @param { put, call, select }
         */
        *checkWechatPayConfirm({ message, callback }, { put, call, select }) {
            try {
                const state: any = yield select((state) => state[Namespaces.orderSubmit]),
                    payBackItem = state!.payBackItem;
                const result = yield call(payService.get, payBackItem.id);

                let isPaySuccess = [TradeStatusEnum.success, TradeStatusEnum.finished].contains(result?.tradeStatus);

                yield put({ type: "input", data: { isPaySuccess } });
                if (callback) {
                    callback(isPaySuccess);
                }
            } catch (e) {
                yield call(message!.error, "getIntegral：" + e.errmsg);
            }
        },

        /**
         * 支付
         */
        *apppay({ message, payway, order, resourceType, isNeedGotoSuccess, wechatCallback }, { put, call }) {
            try {
                let OrderItem: any = [];

                const resourceItems = order?.orderItem?.filter((x) => x.skuType === OrderItemSkuTypeEnum.goods) || [];
                const loyaltys = order?.orderItem?.filter((x) => x.skuType === OrderItemSkuTypeEnum.integral) || [];
                const coupons = order?.orderItem?.filter((x) => x.skuType === OrderItemSkuTypeEnum.coupon) || [];

                let resourceOrderItem = [];
                let loyaltysItem = [];
                let couponItem = [];

                if (isRoom(resourceType)) {
                    resourceOrderItem = resourceItems.map((x) => ({ content: x.skuName, pcs: x.quantity, unitPrice: x.price, unit: x.priceUnitName }));
                } else {
                    resourceOrderItem = resourceItems.length && [
                        { content: resourceItems[0].skuName, pcs: resourceItems.length, unitPrice: resourceItems[0].price, unit: resourceItems[0].priceUnitName },
                    ];
                }

                loyaltysItem = loyaltys.map((x) => ({ content: x.skuName, pcs: x.quantity, unitPrice: 0 - x.price, unit: x.priceUnitName }));
                couponItem = coupons.map((x) => ({ content: x.skuName, pcs: 1, unitPrice: 0 - x.totalAmount, unit: "元" }));

                OrderItem = [...resourceOrderItem, ...loyaltysItem, ...couponItem];

                let returnUrl = `${window.location.protocol + "//" + window.location.host + window.location.pathname}`;
                if (isNeedGotoSuccess) {
                    returnUrl = returnUrl + `/submitSuccess/${order.id}`;
                }

                let params = {
                    id: order.id,
                    bindTableId: `${order.id}`,
                    bindTableName: IParkBindTableNameEnum.order,
                    totalAmount: order.totalAmount,
                    paymentType: PaymentTypeEnum.native,
                    subject: order.subject,
                    returnUrl: returnUrl,
                    orderNo: order.orderNo,
                    orderType: getOrderType(order.orderSubType),
                    items: OrderItem,
                };

                let result;
                if (Number(payway) === PayWayEnum.alipay) {
                    try {
                        result = yield call(cashAliPayService.pay, params);
                        if (result && result.result) {
                            const div = document.createElement("divform");
                            div.innerHTML = result.result;
                            document.body.appendChild(div);
                            document.forms["punchout_form"].submit();
                            window.scroll(0, 0);
                        }
                    } catch (e) {
                        yield call(message!.error, e.errmsg);
                    }
                } else if (Number(payway) === PayWayEnum.wechat) {
                    try {
                        result = yield call(wechatPayService.pay, params);

                        yield put({ type: `input`, data: { payBackItem: result.pay, payBackResult: result.result } });
                        wechatCallback();
                    } catch (e) {
                        if (e?.errmsg?.indexOf("值高于最大值 1000000000") > -1) {
                            yield call(message!.error, "交易失败：总金额不得大于10000000");
                        } else {
                            yield call(message!.error, e.errmsg);
                        }
                    }
                }
            } catch (e) {
                yield call(message!.error, "apppay：" + e.errmsg);
            }
        },
    };
}

app.model(orderSubmitModel);

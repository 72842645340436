import React from "react";
import { router } from "dva";

import { PureComponent } from "@reco-m/core";
import { Button } from "antd";
import { changeUrlParams } from "../util";

/**
 * 标签搜索
 */
export namespace SearchTag {
    export interface IProps extends PureComponent.IProps {
        /**
         * 选项名
         */
        title: string;
        /**
         * 标签集合
         */
        tags: any[];
        /**
         * 选中的值
         */
        value: any;
        /**
         * url中的搜索参数
         */
        searchMap: string;
        /**
         * 是否多选
         */
        isMulty?: boolean;
        /**
         * 额外的搜索参数，已有{ [searchMap]: tag.tagValue }
         */
        extraSearchParams?: any;
        /**
         * 高度大于多少时显示展开按钮，默认为60
         */
        openHeight?: number;
        /**
         * 是否重置路由参数
         */
        isResetSearchParams?: boolean;
        /**
         * 是否需要“不限”选项
         */
        isNeedUnlimited?: boolean;
        /**
         * “不限”选项的文字，默认为“不限”
         */
        unlimitedLabel?: string;
        /**
         * “不限”选项的值，不传则当值为空时选中“不限”
         */
        unlimitedValue?: any;
        /**
         * 改变选项时回调，返回参数 选中的tagValue
         */
        onTagChange?: (tagValue) => void;
    }

    export interface IState extends PureComponent.IState {
        /**
         * 当前值
         */
        curValue: any;
        /**
         * 是否显示展开按钮
         */
        isShowOpenButton: boolean;
        /**
         * 是否展开全部
         */
        isShow: boolean;
    }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends PureComponent.Base<P, S> {
        constructor(props, context) {
            super(props, context);
        }

        componentDidMount() {
            if (this.props.tags?.length) {
                setTimeout(() => {
                    this.searchBox();
                }, 600);
            }
        }

        componentReceiveProps(nextProps: P) {
            if (this.props.value !== nextProps.value) {
                this.setState({ curValue: this.handleValue(nextProps) });
            }
            if (this.props.tags !== nextProps.tags) {
                setTimeout(() => {
                    this.searchBox();
                }, 600);
            }
        }

        /**
         * 处理值
         * @param prop
         * @returns
         */
        handleValue(prop: P) {
            const { isMulty, value } = prop;

            if (isMulty) {
                return Array.isArray(value) ? value : [value];
            } else {
                return Array.isArray(value) ? (value?.length > 0 ? value[0] : undefined) : value;
            }
        }

        /**
         * 搜索栏
         */
        searchBox() {
            const { searchMap } = this.props;
            let searchHeight = $(`#${searchMap}`);

            if (searchHeight.outerHeight()! > (this.props.openHeight || 60)) {
                this.setState({ isShowOpenButton: true });
            }
        }

        /**
         * 渲染展开按钮
         * @returns
         */
        renderSearchPanelOpenButton(): React.ReactNode {
            const { isShowOpenButton, isShow } = this.state;

            return isShowOpenButton ? (
                isShow ? (
                    <Button onClick={() => this.setState({ isShow: false })} size={"small"}>
                        收起
                    </Button>
                ) : (
                    <Button onClick={() => this.setState({ isShow: true })} size={"small"}>
                        展开
                    </Button>
                )
            ) : null;
        }

        /**
         * 判断多选是否包含
         * @param [tagValue]  要判断的值
         * @param [curValues] 当前选中值集合
         * @returns
         */
        isMultyContains(tagValue?, curValues: any[] = []) {
            if (!curValues.length) {
                return !tagValue;
            }
            return curValues.some((a) => a === tagValue);
        }

        /**
         * 判断单选是否选中
         * @param value  要判断的值
         * @param curValue 选中值集合
         * @returns
         */
        isSingleContains(tagValue, curValue) {
            if (!curValue) {
                return !tagValue;
            }
            return curValue === tagValue;
        }

        /**
         * 判断是否选中
         * @param tagValue
         * @param curValue
         * @returns
         */
        isContains(tagValue, curValue) {
            const { isMulty } = this.props;

            if (isMulty) {
                return this.isMultyContains(tagValue, curValue);
            } else {
                return this.isSingleContains(tagValue, curValue);
            }
        }

        /**
         * 多选Value的更替
         * @param tagValue
         * @param [tagValues]
         * @returns
         */
        changeMultyValues(tagValue, curValues: any[] = []) {
            const newTagValues = this.isContains(tagValue, curValues) ? curValues.filter((t) => t !== tagValue) : [...curValues, tagValue];
            if (newTagValues.length > 0) {
                return newTagValues.join(",");
            } else {
                return null;
            }
        }

        /**
         *
         * @param tagValue
         * @param curValue
         * @returns
         */
        changeValue(tagValue?, curValue?) {
            const { isMulty } = this.props;
            if (isMulty) {
                return this.changeMultyValues(tagValue, curValue);
            } else {
                return tagValue;
            }
        }

        /**
         * 渲染不限选项
         * @returns
         */
        renderUnlimited() {
            const { unlimitedValue, unlimitedLabel, searchMap, extraSearchParams, onTagChange } = this.props;
            const { curValue } = this.state;

            return (
                <router.Link
                    key={"i"}
                    to={changeUrlParams(window.location.search, { [searchMap]: null, ...extraSearchParams })}
                    className={"classify" + (this.isContains(unlimitedValue, curValue) ? " classify-checked" : "")}
                    onClick={() => onTagChange && onTagChange(unlimitedValue)}
                >
                    {unlimitedLabel || "不限"}
                </router.Link>
            );
        }

        render(): React.ReactNode {
            const { title, tags, searchMap, extraSearchParams, onTagChange, isResetSearchParams, isNeedUnlimited } = this.props;
            const { isShow, curValue } = this.state;

            return (
                <div className="page-components-rows">
                    <div className="page-components-rows-label">
                        <span className="condition-name">{title}</span>
                    </div>
                    <div className={isShow ? "page-components-rows-content show-content" : "page-components-rows-content"}>
                        <div className="page-components-rows-select">
                            <div id={searchMap} className="select-height">
                                {isNeedUnlimited ? this.renderUnlimited() : null}
                                {tags && tags.length > 0
                                    ? tags.map((tag, idx) => (
                                          <router.Link
                                              key={idx}
                                              to={changeUrlParams(
                                                  location.search,
                                                  {
                                                      [searchMap]: this.changeValue(tag.tagValue, curValue),
                                                      ...extraSearchParams,
                                                  },
                                                  isResetSearchParams
                                              )}
                                              className={"classify" + (this.isContains(tag.tagValue, curValue) ? " classify-checked" : "")}
                                              onClick={() => onTagChange && onTagChange(tag.tagValue)}
                                          >
                                              {tag.tagName}
                                          </router.Link>
                                      ))
                                    : null}

                                {this.renderSearchPanelOpenButton()}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
